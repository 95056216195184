import { ApiService } from '@/modules/common/services/api.service';
import { Api } from '@/modules/common/types/api';
import {
  normalizeAdminOrderDetails,
  normalizeAdminOrdersList,
  normalizeDepthOfBook,
  normalizeOrderDetails,
  normalizeOrdersList,
  normalizeTopOfBook,
  normalizeWatchlistUploadSecuritiesResponse,
  normalizeWatchlistWithInstruments,
} from '@/modules/marketplace/services/marketplace-normalize.service';
import { OrderSide } from '@/modules/marketplace/types/marketplace';
import { ApiError } from '@/utils/errors';
import axios from 'axios';
import Vue from 'vue';
import { normalizeEquity } from '@/modules/common/helpers/api';

export class MarketplaceApiService extends ApiService {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.marketplace = singleton;
    } else {
      v.prototype.$api = {
        marketplace: singleton,
      };
    }
  }

  public async fetchTopOfBook(): Promise<Api.Marketplace.TopOfBookResponse> {
    const url = this.baseUrl + '/orderbook/top-of-book';
    const res = await this.axios.get<Api.Marketplace.Raw.TopOfBookResponse>(url);
    return normalizeTopOfBook(res.data.data);
  }

  public async fetchDepthOfBook(cusip: string): Promise<Api.Marketplace.DepthOfBookResponse> {
    const url = this.baseUrl + `/orderbook/depth-of-book/${cusip}`;
    const res = await this.axios.get<Api.Marketplace.Raw.DepthOfBookResponse>(url);
    return normalizeDepthOfBook(res.data);
  }

  public async fetchAdminTopOfBook(): Promise<Api.Marketplace.TopOfBookResponse> {
    const url = this.baseUrl + '/broker-user/monitor/top-of-book';
    const res = await this.axios.get<Api.Marketplace.Raw.TopOfBookResponse>(url);
    return normalizeTopOfBook(res.data.data);
  }

  public async fetchAdminDepthOfBook(cusip: string): Promise<Api.Marketplace.DepthOfBookResponse> {
    const url = this.baseUrl + `/broker-user/monitor/depth-of-book/${cusip}`;
    const res = await this.axios.get<Api.Marketplace.Raw.DepthOfBookResponse>(url);
    return normalizeDepthOfBook(res.data);
  }

  public async fetchOrdersList(params: {
    showAll: boolean;
    cusip?: string;
  }): Promise<Api.Marketplace.OrdersResponse> {
    const url = this.baseUrl + '/oms/v2/orders';
    const res = await this.axios.get<Api.Marketplace.Raw.OrdersResponse>(url, { params });
    return normalizeOrdersList(res.data.data);
  }

  public async fetchAdminOrdersList(params: {
    showAll: boolean;
    companyId: number | null;
  }): Promise<Api.Marketplace.AdminOrdersResponse> {
    const url = this.baseUrl + '/broker-user/monitor/orders';
    const res = await this.axios.get<Api.Marketplace.Raw.AdminOrdersResponse>(url, { params });
    return normalizeAdminOrdersList(res.data.data);
  }

  public async fetchOrderDetails(
    orderRef: string,
    abortSignal: AbortSignal
  ): Promise<Api.Marketplace.OrderDetails | void> {
    try {
      const url = this.baseUrl + '/oms/v2/orders/' + orderRef;
      const res = await this.axios.get<Api.Marketplace.Raw.OrderDetails>(url, {
        signal: abortSignal,
      });
      return normalizeOrderDetails(res.data);
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async fetchAdminOrderDetails(
    orderRef: string,
    abortSignal: AbortSignal
  ): Promise<Api.Marketplace.AdminOrderDetails | void> {
    try {
      const url = this.baseUrl + '/broker-user/monitor/order/' + orderRef;
      const res = await this.axios.get<Api.Marketplace.Raw.AdminOrderDetails>(url, {
        signal: abortSignal,
      });
      return normalizeAdminOrderDetails(res.data);
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async createOrder(
    payload: Api.Marketplace.OrderCreateRequest
  ): Promise<Api.Marketplace.OrderCreateResponse> {
    const url = this.baseUrl + '/oms/v2/orders';
    const res = await this.axios.post<Api.Marketplace.OrderCreateResponse>(url, payload);
    return res.data;
  }

  public async editOrder(payload: Api.Marketplace.OrderEditRequest): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + payload.orderRef;
    return this.axios.put(url, payload).then(() => undefined);
  }

  public async cancelOrder(orderRef: string): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + orderRef + '/cancel';
    return this.axios.post(url).then(() => undefined);
  }

  public async routeOrder(orderRef: string): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + orderRef + '/route';
    return this.axios.post(url).then(() => undefined);
  }

  public async unrouteOrder(orderRef: string): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + orderRef + '/unroute';
    return this.axios.post(url).then(() => undefined);
  }

  public async uploadOrders(request: {
    file: File;
    side: OrderSide;
  }): Promise<Api.Marketplace.BasketResponse> {
    const url = this.baseUrl + '/oms/v2/orders/basket-parse';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', request.file);
    formData.append('side', request.side);

    try {
      const { data } = await this.axios.post<Api.Marketplace.BasketResponse>(url, formData, {
        headers,
      });

      data.entries.forEach((item) => normalizeEquity(item.equity));

      return data;
    } catch (e) {
      const apiError = e as ApiError;
      const data = apiError.responseData;
      // There's a possibility that the error has useful data
      if (Array.isArray(data?.entries)) {
        data.entries.forEach((item) => normalizeEquity(item.equity));
      }
      throw e;
    }
  }

  public async batchCancelOrders(ids: string[]): Promise<Api.Marketplace.BatchActionResponse> {
    const payload = {
      orderRefs: ids,
    };
    const url = this.baseUrl + '/oms/v2/orders/cancel';
    const { data } = await this.axios.post<Api.Marketplace.BatchActionResponse>(url, payload);
    return data;
  }

  public async batchUpdate(
    payload: Api.Marketplace.BatchModifyPayload
  ): Promise<Api.Marketplace.BatchActionResponse> {
    const url = this.baseUrl + '/oms/v2/orders/modify';
    const { data } = await this.axios.put(url, payload);
    return data;
  }

  public async createOrders(request: Api.Marketplace.BasketResponse): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/basket';
    await this.axios.post(url, request);
  }

  public async fetchWatchlists(): Promise<Api.Marketplace.TopOfBookWatchlist[]> {
    const url = this.baseUrl + '/orderbook/watchlists';
    const res = await this.axios.get<Api.Marketplace.Raw.TopOfBookWatchlistsResponse>(url);
    return res.data.items;
  }

  public async fetchWatchlistWithInstruments(
    displayId: string
  ): Promise<Api.Marketplace.TopOfBookWatchlistWithInstrumentsResponse> {
    const url = this.baseUrl + `/orderbook/watchlists/${displayId}/instruments`;
    const res =
      await this.axios.get<Api.Marketplace.Raw.TopOfBookWatchlistWithInstrumentsResponse>(url);
    return normalizeWatchlistWithInstruments(res.data);
  }

  public async fetchWatchlistContent(
    displayId: string
  ): Promise<Api.Marketplace.TopOfBookResponse> {
    const url = this.baseUrl + `/orderbook/watchlists/${displayId}`;
    const res = await this.axios.get<Api.Marketplace.Raw.TopOfBookWatchlistContent>(url);
    return normalizeTopOfBook(res.data.data);
  }

  public async deleteWatchlist(displayId: string): Promise<void> {
    const url = this.baseUrl + `/orderbook/watchlists/${displayId}`;
    await this.axios.delete(url);
  }

  public async createWatchlist(
    payload: Api.Marketplace.TopOfBookWachlistFormRequest
  ): Promise<void> {
    const url = this.baseUrl + '/orderbook/watchlists';
    return this.axios.post(url, payload).then(() => undefined);
  }

  public async updateWatchlist(params: {
    displayId: string;
    payload: Api.Marketplace.TopOfBookWachlistFormRequest;
  }): Promise<void> {
    const url = this.baseUrl + `/orderbook/watchlists/${params.displayId}`;
    return this.axios.put(url, params.payload).then(() => undefined);
  }

  public async uploadWatchlistSecurities(
    file: File
  ): Promise<Api.Marketplace.TopOfBookWatchlistSecuritiesUploadResponse> {
    const url = this.baseUrl + '/orderbook/watchlists/file-upload';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);

    const { data } =
      await this.axios.post<Api.Marketplace.Raw.TopOfBookWatchlistSecuritiesUploadResponse>(
        url,
        formData,
        { headers }
      );

    return normalizeWatchlistUploadSecuritiesResponse(data);
  }
}
