<template>
  <v-data-table
    class="d-flex flex flex-column"
    :custom-sort="customSortTopOfBook"
    data-test="top-of-book-table"
    dense
    disable-pagination
    fixed-header
    :headers="tableColumns"
    height="100%"
    hide-default-footer
    :items="items"
    must-sort
    :sort-by.sync="sortBy"
    @click:row="handleRowClick"
  >
    <template #[`item.borrow.totalQuantity`]="{ item }">
      <pretty-number v-if="item.borrow" :value="item.borrow.totalQuantity" />
    </template>
    <template #[`item.borrow.counterparties`]="{ item }">
      <span v-if="item.borrow">{{ formatCounterparties(item.borrow.counterparties) }}</span>
      <span v-else>&mdash;</span>
    </template>
    <template #[`item.borrow.quantity`]="{ item }">
      <span v-if="item.borrow"><pretty-number :value="item.borrow.quantity" /></span>
      <span v-else>&mdash;</span>
    </template>
    <template #[`item.borrow.rate`]="{ item }">
      <rate-output v-if="item.borrow" class="borrower-color" :rate="item.borrow.rate" />
      <span v-else>&mdash;</span>
    </template>
    <template #[`item.lend.rate`]="{ item }">
      <rate-output v-if="item.lend" class="lender-color" :rate="item.lend.rate" />
      <span v-else>&mdash;</span>
    </template>
    <template #[`item.lend.quantity`]="{ item }">
      <span v-if="item.lend"><pretty-number :value="item.lend.quantity" /></span>
      <span v-else>&mdash;</span>
    </template>
    <template #[`item.lend.counterparties`]="{ item }">
      <span v-if="item.lend">{{ formatCounterparties(item.lend.counterparties) }}</span>
      <span v-else>&mdash;</span>
    </template>
    <template #[`item.lend.totalQuantity`]="{ item }">
      <span v-if="item.lend"><pretty-number :value="item.lend.totalQuantity" /></span>
      <span v-else>&mdash;</span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { Api } from '@/modules/common/types/api';
import {
  customSortTopOfBook,
  formatCounterparties,
} from '@/modules/marketplace/helpers/marketplace';
import { formatDate } from '@/utils/helpers/dates';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';

const allTableHeaders: DataTableHeader[] = [
  {
    text: i18n.tc('Ticker'),
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('CUSIP'),
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate highlighted-border-right',
  },
  {
    text: i18n.tc('Total Demand'),
    value: 'borrow.totalQuantity',
    class: 'text-truncate text-end',
    cellClass: 'text-truncate text-end',
  },
  {
    text: i18n.tc('Counterparty'),
    value: 'borrow.counterparties',
    class: 'text-truncate text-end',
    cellClass: 'text-truncate text-end',
  },
  {
    text: i18n.tc('Quantity'),
    value: 'borrow.quantity',
    class: 'text-truncate text-end',
    cellClass: 'text-truncate text-end',
  },
  {
    text: i18n.tc('Best Bid'),
    value: 'borrow.rate',
    class: 'text-truncate text-end',
    cellClass: 'text-truncate highlighted-border-right text-end',
  },
  {
    text: i18n.tc('Best Ask'),
    value: 'lend.rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Quantity'),
    value: 'lend.quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Counterparty'),
    value: 'lend.counterparties',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Total Supply'),
    value: 'lend.totalQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
];

@Component({
  props: {
    items: Array as PropType<Api.Marketplace.TopOfBookResponse>,
    asBroker: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RateOutput,
  },
})
export default class MarketplaceTopOfBookTable extends Vue {
  // props
  protected items!: Api.Marketplace.TopOfBookResponse;
  protected readonly asBroker!: boolean;
  protected tableColumns = allTableHeaders;
  protected sortBy = ['equity'];
  protected formatCounterparties = formatCounterparties;
  protected customSortTopOfBook = customSortTopOfBook;

  // @TODO: make a proper link so users can open in a new tab, for example
  protected handleRowClick(item: Api.Marketplace.TopOfBookResponseItem): void {
    if (this.asBroker) {
      void this.$router.push({
        name: 'broker-user.client-monitor.marketplace-depth-of-book',
        params: { cusip: item.equity.cusip },
      });
    } else {
      void this.$router.push({
        name: 'marketplace.symbol-overview',
        params: { cusip: item.equity.cusip },
      });
    }
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM dd');
  }

  protected formatTime(value: Date): string {
    return formatDate(value, 'HH:mm');
  }
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
}

::v-deep {
  // hide sorting icons but preserve sorting functionality because we right align some content, and the icon messes up the alignment
  th .v-icon {
    display: none;
  }

  td {
    cursor: pointer;
  }

  .highlighted-border-right {
    border-right: 1px solid #ccc !important; /* @TODO: fix */
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }
  }
}
</style>
